// vendors
import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"

// components
import Layout from "../components/Layout"
import Seo from "../components/Seo"

// utils
import { fontSizes, colors } from "../styles/variables"
import mediaQuery from "../utils/media-query"
import wrapper from "../utils/wrapper"

// images
import illustrationPathWithPin from "../images/illustration-pathWithPin.svg"
import EventGrid from "../components/EventGrid"
import IllustrationPathWithPin from "../images/IllustrationPathWithPin"

const EvenementPage = ({ data }) => {
  const breakpoint = 1024

  const seminarId = data.seminars.edges[0].node.id

  const events = ((data.events || {}).edges || [])
    .filter(({ node: { fromDate } }) => {
      const now = new Date()
      now.setHours(0, 0, 0, 0)

      return new Date(fromDate) > now
    })
    .map(({ node }) => {
      const to =
        node.id !== seminarId
          ? `/evenements/${((node.meta || {}).slug || {}).current}`
          : `/colloque`

      return {
        picture: { ...node.thumbnail?.asset?.fluid, alt: "" },
        date: node.fromDate || null,
        name: node.title || null,
        place: node.placeName || null,
        city: (node.address || {}).city || null,
        onlineUrl: node.online ? node.onlineUrl : null,
        excerpt: "",
        to,
      }
    })

  return (
    <Layout>
      <Seo
        description="Actualité en médecine: ça bouge avec Sarros! Découvrez la liste des événements à venir dans lesquels nous pourrons vous parler des charmes de la médecine en région."
        lang="fr"
        keywords={["actualité médecine"]}
        title="Actualité médecine: liste complète des événements à venir"
      />

      <article>
        <section
          css={css`
            ${wrapper.bolt()}
            ${mediaQuery.greaterThen(breakpoint)} {
              display: grid;

              grid-template-columns: minmax(260px, 538px) minmax(260px, auto);
              grid-template-rows: 1fr 0.85fr;
              align-items: end;
              grid-column-gap: 20px;
            }
          `}
        >
          <h1
            css={css`
              ${mediaQuery.greaterThen(breakpoint)} {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
                ${wrapper.paddingLeft()}
              }
            `}
          >
            Les événements SARROS
          </h1>

          <div
            css={css`
              grid-column: span 1;
              grid-row: span 2;
            `}
          >
            <p className="intro">
              Tout au long de l’année, l’équipe SARROS se fait un point
              d’honneur d’aller à la rencontre des étudiants, externes et
              nouveaux médecins dans le cadre de divers événements.
            </p>

            <p>
              L’équipe SARROS croit en effet qu’il n’existe pas de meilleure
              façon que le contact humain pour présenter et faire vivre une
              région. C’est notre manière à nous d’amener les régions aux portes
              des grandes villes. Bien souvent, ces rencontres sont l’occasion
              pour vous d’amorcer les premières démarches vers votre nouvelle
              vie, en plus de nous permettre de répondre en personne à vos
              questions et de vos conforter dans votre choix de territoire.
            </p>
          </div>

          <div
            css={css`
              position: relative;
              ${mediaQuery.lessThen(breakpoint)} {
                text-align: center;
                margin-bottom: -${125 / fontSizes[0]}em;
              }
              ${mediaQuery.greaterThen(breakpoint)} {
                grid-column: 1 / span 1;
                align-self: start;
              }
            `}
          >
            <IllustrationPathWithPin
              pointer
              css={css`
                width: calc(100% - 60px);
                max-width: 478px;
                margin-right: 60px;

                ${mediaQuery.greaterThen(breakpoint)} {
                  position: absolute;
                  top: 0;
                }
              `}
            />
          </div>
        </section>

        <section
          css={css`
            ${wrapper.bolt("padding")}
            margin-top: ${fontSizes[9] / fontSizes[0]}em;
            padding-top: ${fontSizes[9] / fontSizes[0]}em;
            padding-bottom: ${fontSizes[9] / fontSizes[0]}em;
            background-color: ${colors.veryLightGreen};
          `}
        >
          <h2
            css={{
              textAlign: "center",
              margin: `${fontSizes[2] / fontSizes[5]}em auto ${
                fontSizes[7] / fontSizes[5]
              }em auto`,
            }}
          >
            Les événements en vedette
          </h2>

          <div
            css={css`
              position: relative;
            `}
          >
            <IllustrationPathWithPin
              dot
              css={css`
                width: 182px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(75%, -50%) rotateZ(-10deg);
                z-index: 0;

                ${mediaQuery.greaterThen(breakpoint)} {
                  top: -210px;
                  width: 400px;
                }
              `}
            />
          </div>

          <EventGrid events={events} breakpoint={breakpoint} />
        </section>
      </article>
    </Layout>
  )
}

export default EvenementPage

export const query = graphql`
  query EvenementPageQuery {
    events: allSanityEvent(sort: { order: ASC, fields: fromDate }) {
      edges {
        node {
          id
          address {
            city
          }
          online
          onlineUrl
          fromDate
          placeName
          thumbnail {
            asset {
              fluid {
                src
                srcSet
                srcSetWebp
              }
            }
          }
          title
          meta {
            slug {
              current
            }
          }
        }
      }
    }
    seminars: allSanityEvent(
      filter: {
        meta: { slug: { current: { ne: null } } }
        type: { in: ["seminar"] }
      }
      sort: { fields: fromDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`
